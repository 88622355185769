<template>
	<div class="package">
		<Nav></Nav>
		<div class="author">
			<h2 class="package-title"><i class="el-icon-paperclip"></i>画师打包<i class="el-icon-paperclip"></i></h2>
			<el-row>
			  <el-col :span="5" v-for="(author, index) in tempAuthors" :key="index" :offset="0">
			    <el-card :body-style="{ padding: '0px' }" shadow="hover">
				  <div v-if="author.count==2" class="image-two">
					  <img v-lazy="'https://pixiv.re/'+author.cover.split(',')[0]+'.jpg'" class="image image-r" preview='0' :preview-text="'画师：'+author.illustrator">
					  <img v-lazy="'https://pixiv.re/'+author.cover.split(',')[1]+'.jpg'" class="image image-l" preview='0' :preview-text="'画师：'+author.illustrator">
				  </div>
			      <div v-if="author.count==1" class="image-one">
					  <img v-lazy="'https://pixiv.re/'+author.cover+'.jpg'" class="image" preview='0' :preview-text="'画师：'+author.illustrator">
			      </div>
			      <div class="image-info">
					<el-link type="primary" :href="author.authorLink" class="authorlink" target="_blank" v-text="author.illustrator"></el-link>
			        <div class="bottom clearfix">
			          <time class="time" v-text="$moment(author.packTime).format('YYYY-MM-DD')"></time>
					  <el-link type="primary" :href="author.duPanLink" class="panlink" target="_blank">度盘({{author.duCode}})</el-link>
			        </div>
			      </div>
			    </el-card>
			  </el-col>
			</el-row>
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :pager-count="5"
			  :total="authors.length"
			  @current-change='handleCurrentChange'
			  class="article-pager">
			</el-pagination>
		</div>
		<div class="artbook">
			<h2 class="package-title"><i class="el-icon-paperclip"></i>画集打包<i class="el-icon-paperclip"></i></h2>
			<el-row>
			  <el-col :span="5" v-for="(artbook, index) in tempArtbooks" :key="index" :offset="0">
			    <el-card :body-style="{ padding: '0px' }" shadow="hover">
				  <div v-if="artbook.count==2" class="image-two">
					  <img v-lazy="artbook.cover.split(',')[0]" class="image image-r" preview='1' :preview-text="'画师：'+artbook.illustrator">
					  <img v-lazy="artbook.cover.split(',')[1]" class="image image-l" preview='1' :preview-text="'画师：'+artbook.illustrator">
				  </div>
			      <img v-if="artbook.count==1" v-lazy="artbook.cover" class="image" preview='1' :preview-text="'画师：'+artbook.illustrator">
			      <div style="padding: 14px;">
					<el-link type="primary" :href="artbook.authorLink" class="authorlink" target="_blank" v-text="'画师：'+artbook.illustrator"></el-link>
			        <p class="book-title" v-text="artbook.bookTitle"></p>
			        <div class="bottom clearfix">
			          <time class="time"  v-text="$moment(artbook.packTime).format('YYYY-MM-DD')"></time>
					  <el-link type="primary" :href="artbook.duPanLink" class="panlink">度盘({{artbook.duCode}})</el-link>
			        </div>
			      </div>
			    </el-card>
			  </el-col>
			</el-row>
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :pager-count="5"
			  :total="artbooks.length"
			  @current-change='handleCurrentChangeBook'
			  class="article-pager">
			</el-pagination>
		</div>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
export default {
	metaInfo: {
	    title: '画集 - KASUIEの次元',
	    meta: [
	  	  {
	  		  name: 'description',
	          content: '画师，画集，插画，pixiv',
	  	  },
	    ]
	},
	components: {
		Nav
	},
	data() {
		return {
			packages: [],
			authors: [],
			artbooks: [],
			tempAuthors: [],
			tempArtbooks: [],
		}
	},
	methods: {
		getPackage(){
			let _this = this
			this.axios.get('packages').then(function(res){
				_this.packages = res.data
				for (let pack of res.data) {
					if(pack.artBook){
						_this.artbooks.push(pack)
					}else{
						_this.authors.push(pack)
					}
				}
				_this.tempAuthors = _this.authors.slice(0,8)
				_this.tempArtbooks = _this.artbooks.slice(0,8)
			},function(err){
				console.log(err);
			})
		},
		handleCurrentChange(val){
			this.tempAuthors = this.authors.slice(8*(val-1),8*val)
		},
		handleCurrentChangeBook(val){
			this.tempArtbooks = this.artbooks.slice(8*(val-1),8*val)
		}
	},
	created() {
		this.getPackage()
	},
	mounted() {
		document.body.style.overflow='hidden'
		setTimeout(() => {
			this.$store.commit('showLoading')
			document.body.style.overflow=''
		},200)
	},
	destroyed() {
		this.$store.commit('loading')
	}
}
</script>

<style>
.package{
	margin: 5em auto;
}
.author,.artbook{
	margin-bottom: 5em;
}
.package-title{
	display: inline-block;
	color: white;
}
.package-title:hover{
	color: pink;
}
.package .el-card{
	border: none;
	background-color: var(--theme-background-color);
}
.package .el-col-offset-0{
	margin: 1em 2em;
}
.author .el-row,.artbook .el-row{
	width: 90%;
	margin: 2em auto;
}

.time {
	/* position: absolute; */
	/* left: 0; */
	float: left;
    font-size: 13px;
    color: var(--theme-color);
  }
  
  .bottom {
	position: relative;
    margin-top: 13px;
    line-height: 12px;
  }
  
  .artbook .bottom{
	  margin-top: .5em;
  }
  
  .authorlink{
	  text-align: start;
  }

  .panlink {
    padding: 0;
    float: right;
  }
  
  .image-two,.image-one{
	  height: 250px;
	  overflow: hidden;
  }

  .image-info{
	  padding: 14px;
  }
  
  .book-title{
	  color: var(--theme-color);
	  height: 3em;
	  margin-top: .2em;
	  font-size: .9em;
	  text-align: start;
  }

  .image {
    width: 100%;
    display: block;
	height: 250px;
	object-fit: cover;
	transition: all ease-in-out .3s;
  }

  .image-l,.image-r{
	  width: 50%;
	  display: inline-block;
  }
  
  .image:hover{
	  transform: scale(1.1);
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
  .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
  	color: var(--theme-color);
	background-color: var(--theme-background-color);
  }
 @media screen and (max-width: 770px) {
 	.el-row .el-col-5{
		width: 85%;
	}
	.author .el-row,.artbook .el-row{
		width: 95%;
	}
	.image-info{
		padding: 7px;
	}
	.image,.image-one,.image-two{
		height: 200px;
	}
 }
</style>
